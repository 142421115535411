<div class="breadcrumbs">
    Daily Read | /<a routerLink="/">home</a>/<a routerLink="/subscriptions">subscriptions</a>/{{email}}
</div>
<div class="mainWider center">
    <h2>Subscriptions</h2>
    <div class="subMessage">Subscriptions will be automatically deleted after two years.</div>
    <div *ngIf="!!updateStatus" [ngClass]="!updateStatus.includes('successful')  ? 'form-error' : 'form-success'">
        {{updateStatus}}
    </div>
    <ng-container [ngSwitch]="!subscriptions">
        <app-spinner *ngSwitchCase="true"></app-spinner>
        <ng-container *ngSwitchCase="false">
            <table class="sub-table">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Title</th>
                        <th>Send Time</th>
                        <th>Chapter</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let subscription of subscriptions">
                        <td>{{subscription.status}}</td>
                        <td><a routerLink="/titles/{{subscription.titleAlias}}">{{subscription.title}}</a></td>
                        <td>{{subscription.sendTime}} (GMT)</td>
                        <td>{{subscription.nextEntry}}/{{subscription.numberOfEntries}}</td>
                        <td>
                            <button *ngIf="subscription.status === 'In Progress'" (click)="toggleSubscription(subscription.email, subscription.titleAlias, 'stop')">Stop Subscription</button>
                            <button *ngIf="subscription.status === 'Stopped'" (click)="toggleSubscription(subscription.email, subscription.titleAlias, 'start')">Resume Subscription</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </ng-container>
</div>